import React from 'react'
import PropTypes from 'prop-types'
import { kebabCase } from 'lodash'
import { Helmet } from 'react-helmet'
import { graphql, Link } from 'gatsby'
import Content, { HTMLContent } from '../components/Content'
import Layout from '../components/Layout'

export const PressPostTemplate = ({
  content,
  contentComponent,
  title,
  sourcename,
  externallink,
  tags,
  helmet,
}) => {
  const PostContent = contentComponent || Content
  
  return (
    <section className="section-post">

      <section className="page-header">
        <div className="container">
          <div className="content subheaderlinks">
            <Link to="/press" className="post-header-link">
              <span className="breadcrumbs-links">
                Press
              </span>
            </Link>
            <span className="spacing-item">/</span>
            <span className="breadcrumbs-links">
              {sourcename}
            </span>
          </div>
        </div>
      </section>

      <div className="container content">
        
      {helmet || ''}

      <section className="section">
        <div className="columns">
          
          <div className="column press-post">
            <div className="press-source">{sourcename}</div>
            <h1 className="name is-size-2 has-text-weight-bold is-bold-light">
              {title}
            </h1>
            <div className="press-content">
              <PostContent content={content} />
            </div>
            {externallink ? (<p><a href={externallink} className="sociallinks" target="_blank">Press Link</a></p>) : null}
            <p>{tags && tags.length ? (
              <div>
                <hr />
                <h4>Tags</h4>
                <ul className="taglist">
                  {tags.map((tag) => (
                    <li key={tag + `tag`}>
                      <Link to={`/tags/${kebabCase(tag)}/`}>{tag}</Link>
                    </li>
                  ))}
                </ul>
              </div>
            ) : null}
            </p>
          </div>

        </div>
      </section>

      </div>
    </section>
  )
}

PressPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  title: PropTypes.string,
  shortintro: PropTypes.string,
  helmet: PropTypes.object,
}

const PressPost = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <PressPostTemplate
        content={post.html}
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        shortintro={post.frontmatter.shortintro}
        sourcename={post.frontmatter.sourcename}
        externallink={post.frontmatter.externallink}
        helmet={
          <Helmet titleTemplate="%s | Press">
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.shortintro}`}
            />
          </Helmet>
        }
        tags={post.frontmatter.tags}
      />
    </Layout>
  )
}

PressPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default PressPost

export const pageQuery = graphql`
  query PressPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        templateKey
        title
        shortintro
        sourcename
        externallink
        tags
      }
    }
  }
`
